<template>
  <div class="w-screen h-screen flex items-center justify-center">
    <img alt="fit4future Logo" class="max-w-lg" src="../assets/logo.jpg" />
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
}
</script>
